import React, { FC, useEffect, useState } from 'react';
import NormalHeader from '../NormalHeader/NormalHeader';
import classes from './NormalMode.module.scss';
import classNames from 'classnames';
import { TerminalMode } from '../../../../../pagesMain/TerminalPage/TerminalPage';
import {
  CategoryTopicsType,
  CategoryType,
  OrderUnitsType,
  TopicsType
} from '../../../../../types/Terminal/TerminalTypes';
import { useGetTicket } from '../../../../../hooks/TerminalServices/useGetTicket';
import TerminalModal from '../../../Modals/TerminalModal/TerminalModal';
import PrintedTicketModal from '../../../Modals/ModalPrintedTicket/ModalPrintedTicket';
import { isAfterPrintTicketEnabled } from '../../../../../constants/API';

export type languageType = 'en' | 'by' | 'ru';

type NormalModePropsType = {
  setMode: (mode: TerminalMode) => void;
  orderUnitsData: OrderUnitsType;
  tickerText?: string;
};

const elementsLocalization = {
  returnBtn: {
    ru: 'Вернуться назад',
    by: 'Вярнуцца назад',
    en: 'Go back'
  },
  title: {
    ru: 'Добро пожаловать, дорогой клиент!',
    by: 'Сардэчна запрашаем, дарагі кліент!',
    en: 'Welcome, dear customer!'
  },
  subTitle: {
    ru: 'Пожалуйста, выберите услугу:',
    by: 'Калі ласка, абярыце паслугу:',
    en: 'Please select a service:'
  }
};

export const NormalMode: FC<NormalModePropsType> = ({ setMode, tickerText, orderUnitsData }) => {
  const [language, setLanguage] = useState<languageType>('ru');
  const { getTicket, isLoadingGetTicket } = useGetTicket();

  const [currentSectionsServices, setCurrentSectionsServices] = useState([]);
  const [history, setHistory] = useState<{ [key: string]: CategoryTopicsType[] }>({});
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [activeModal, setActiveModal] = useState(false);
  const [activeTicketId, setActiveTicketId] = useState<number>();

  useEffect(() => {
    if (isLoadingGetTicket) {
      setActiveModal(true);
    } else {
      setActiveModal(false);
    }
  }, [isLoadingGetTicket]);

  useEffect(() => {
    if (orderUnitsData) {
      setCurrentSectionsServices(transformOrderUnitsToArr(orderUnitsData));
    }
  }, [orderUnitsData]);

  const handleCategoryClick = (category: CategoryTopicsType) => {
    if (checkIsCategory(category)) {
      setHistory((prev) => {
        const newHistory = { ...prev };
        newHistory[currentIndex] = currentSectionsServices;
        return newHistory;
      });
      setCurrentSectionsServices([...category.topics, ...category.subCategories]);
      setCurrentIndex((prev) => prev + 1);
    } else {
      setActiveTicketId(category.id);
      getTicket({ topicId: category.id });
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
      setCurrentSectionsServices(history[currentIndex - 1]);
    }
  };

  function transformOrderUnitsToArr(data: OrderUnitsType) {
    const topics = data.categoryView.topics || [];
    const categories = data.categoryView.categories || [];
    const processCategory = (category: CategoryTopicsType, prefix = '') => {
      const categoryId = `${prefix}${category.id}`;
      let subCategories = [];
      if (category.subCategories && category.subCategories.length > 0) {
        subCategories = category.subCategories.map((subCategory: CategoryTopicsType) => {
          return processCategory(subCategory, `${categoryId}.`);
        });
      }
      return {
        ...category,
        id: categoryId,
        subCategories: subCategories
      };
    };

    const processedCategories = categories.map((category: CategoryTopicsType) =>
      processCategory(category)
    );

    return [...topics, ...processedCategories];
  }

  function checkIsCategory(categoryOrTopic: CategoryTopicsType) {
    return (
      (categoryOrTopic.subCategories && categoryOrTopic.subCategories.length > 0) ||
      (categoryOrTopic.topics && categoryOrTopic.topics.length > 0)
    );
  }

  const getTicketName = (ticket: TopicsType | CategoryType, language: languageType): string => {
    switch (language) {
      case 'ru':
        return ticket.nameRu;
      case 'en':
        return ticket.nameEn;
      case 'by':
        return ticket.nameBe;
      default:
        return ticket.nameRu;
    }
  };

  return (
    <div className={classes.normalModeContainer}>
      {activeModal && <TerminalModal activeModal={activeModal} setActiveModal={setActiveModal} />}
      <div className={classes.headerContainer}>
        <NormalHeader language={language} setLanguage={setLanguage} setMode={setMode} />
      </div>
      <main className={classes.normalModeMain}>
        {isAfterPrintTicketEnabled && (
          <PrintedTicketModal activeModal={true} setActiveModal={setActiveModal} />
        )}

        <div className={classes.chooseServiceText}>
          {currentIndex !== 0 && (
            <button onClick={handleBack} className={classes.buttonBack}>
              <div className={classes.buttonBackArrow} />
              <span>{elementsLocalization.returnBtn[language]}</span>
            </button>
          )}
          {currentIndex === 0 && (
            <div>
              <p>{elementsLocalization.title[language]}</p>
              <p>{elementsLocalization.subTitle[language]}</p>
            </div>
          )}
        </div>
        <div
          className={classNames(classes.ticketList, {
            [classes.ticketListLargeIcons]: currentSectionsServices.length <= 3,
            [classes.ticketListSmallIcons]: currentSectionsServices.length > 3,
            [classes.isBackBtnActive]: currentIndex !== 0
          })}>
          {currentSectionsServices.length > 0 &&
            currentSectionsServices.map((ticket: CategoryTopicsType, index: number) => {
              const isBtnDisabled =
                (!ticket.isActiveNow && !checkIsCategory(ticket)) ||
                (isLoadingGetTicket && activeTicketId === ticket.id) ||
                activeModal;

              return (
                <button
                  className={classNames(classes.ticketCardContainer)}
                  key={`${ticket.id}_${index}`}
                  onClick={() => handleCategoryClick(ticket)}
                  disabled={isBtnDisabled}>
                  <div
                    className={classNames({
                      [classes.active]: ticket.isActiveNow || checkIsCategory(ticket),
                      [classes.ticketCardSmall]: !checkIsCategory(ticket),
                      [classes.ticketCardLarge]: checkIsCategory(ticket)
                    })}>
                    <div
                      className={classNames({
                        [classes.ticketOuterCircleSmall]: !checkIsCategory(ticket),
                        [classes.ticketOuterCircleLarge]: checkIsCategory(ticket)
                      })}>
                      <div
                        className={classNames({
                          [classes.ticketInnerCircleSmall]: !checkIsCategory(ticket),
                          [classes.ticketInnerCircleLarge]: checkIsCategory(ticket)
                        })}
                      />
                    </div>
                    <div className={classes.ticketTextWrapper}>
                      <span>{getTicketName(ticket, language)}</span>
                    </div>
                    <div
                      className={classNames({
                        [classes.cropLineShow]: !checkIsCategory(ticket),
                        [classes.cropLineHide]: checkIsCategory(ticket)
                      })}></div>
                  </div>
                </button>
              );
            })}
        </div>
      </main>
      <footer>
        <div className={classes.normalModeFooter}>
          <div className={classes.tickerText}>{tickerText ? tickerText : ''}</div>
        </div>
      </footer>
    </div>
  );
};

export default NormalMode;
