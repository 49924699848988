import { useMutation } from 'react-query';
import { StaticTokens, TerminalServicesAPI } from '../../constants/API';
import axios from 'axios';

type TicketDataType = {
  topicId: number;
};

export const useGetTicket = () => {
  const {
    mutate: getTicket,
    isLoading: isLoadingGetTicket,
    isError
  } = useMutation(
    ['getTicket'],
    (data: TicketDataType) =>
      axios.post(TerminalServicesAPI.getTicketInTerminal, data, {
        headers: {
          'X-API-TOKEN': StaticTokens.terminalToken
        }
      }),
    {}
  );

  return { getTicket, isLoadingGetTicket, isError };
};
